import React from 'react';
import '../../App.css';
import AboutCard from '../AboutCard';


export default function About (){
    return(
        <div  className='services'>
           <AboutCard />
        </div>

        
       
    );
}
