import React from 'react';
import '../../App.css';
import ContactCard from '../ContactCard';

export default function Contact (){
    return(
        <div  className='services'>
            <ContactCard />
        </div>



    ); 
}
